import React from "react"
import Container from "../components/container"
import Text from "../components/text"
import Title from "../components/title"
import Social from "../components/social"
import Nav from "../components/nav"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useSpring, } from "react-spring"
import { Spring } from "react-spring/renderprops"
import { MenuIcon } from "../components/menu"
import GlobalStyles from './styles/globalStyles'
import "./styles/index.css"

export default ({ data }) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } })
  const [navStatus, displayNav] = React.useState(false)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
        <link rel="canonical" href="https://tomlarge.dev" />
      </Helmet>
      <GlobalStyles/>
      
      <Container>
        {navStatus && (
          <Spring
            from={{ transform: `translate3d(-100%, 0, 0)` }}
            to={{ transform: `translate3d(0, 0, 0)` }}
          >
            {props => <Nav style={props} />}
          </Spring>
        )}
        <MenuIcon onClick={() => displayNav(!navStatus)} open={navStatus} />
        <Title style={props}>Hello<span role="img" aria-label="wave">👋</span></Title>
        <Title style={props}>{data.site.siteMetadata.subtitle}</Title>
        <Title style={props}>{data.site.siteMetadata.greeting}</Title>
        <Text style={props}>
          I am a experienced developer with 5+ years of working in the
          design and web industry. Skilled in React, React Native, Node, ES6+, 
          Ruby/Rails. I also have a background in design with a
          Bachelor of Arts (B.A.) focused in Graphic Design from Coventry
          University <span role="img" aria-label="graduation">👨‍🎓</span>
        </Text>
      </Container>
      <Social />
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        subtitle
        greeting
      }
    }
  }
`
